import { Action } from "typescript-fsa";
import { put, takeEvery } from "redux-saga/effects";
import loanProcessActions from "../actions/loanProcessActions";
import {
  IDownloadGSTRequest,
  IDownloadGSTResponse,
  IGenerateOtpRequest,
  IGenerateOtpResponse,
  IGSTCredentials,
  ILoanProcessResponse,
  ITokenRequest,
  IValidateOtpRequest,
  IValidateOtpResponse,
} from "../reducers/typings";
import LoanProcessServices from "../../services/api";

const partner = "jitfin";

function* generateGstOtp(action: Action<IGSTCredentials>) {
  const { payload } = action;
  const params: IGenerateOtpRequest = { ...payload, partner };
  try {
    const response: ILoanProcessResponse<IGenerateOtpResponse> =
      yield LoanProcessServices.generateOtp(params);
    if ((response as any)?.status === 200 && (response as any)?.data.message) {
      const result: IGenerateOtpResponse = {
        message: (response as any)?.data.message,
        otpGenerated: true,
      };
      yield put(
        loanProcessActions.generateGstOtpActions.done({
          result: result,
          params: payload,
        })
      );
    } else {
      yield put(
        loanProcessActions.generateGstOtpActions.failed({
          params: payload,
          error: response.errorCode as any,
        })
      );
    }
  } catch (err) {
    yield put(
      loanProcessActions.generateGstOtpActions.failed(
        (err as any)?.response?.data?.errors || (err as any).message || err
      )
    );
  }
}

function* validateGstOtp(action: Action<IValidateOtpRequest>) {
  const { payload } = action;
  const params: IValidateOtpRequest = { ...payload, partner };
  try {
    const response: ILoanProcessResponse<IValidateOtpResponse> =
      yield LoanProcessServices.validateOtp(params);
    if ((response as any)?.status === 200 && (response as any)?.data.message) {
      const result: IValidateOtpResponse = {
        message: (response as any)?.data.message,
        validated: true,
        otp: payload.otp,
      };
      yield put(
        loanProcessActions.validateGstOtpActions.done({
          result: result,
          params: payload,
        })
      );
    } else {
      yield put(
        loanProcessActions.validateGstOtpActions.failed({
          params: payload,
          error: response.errorCode as any,
        })
      );
    }
  } catch (err) {
    yield put(
      loanProcessActions.validateGstOtpActions.failed(
        (err as any)?.response?.data?.errors || (err as any).message || err
      )
    );
  }
}

function* getToken(action: Action<ITokenRequest>) {
  const { payload: params } = action;
  try {
    yield LoanProcessServices.getToken(params);
  } catch (err) {
    yield put(
      loanProcessActions.getTokenActions.failed(
        (err as any)?.response?.data?.errors || (err as any).message || err
      ));
  }
}

function* downloadGSTData(action: Action<IDownloadGSTRequest>) {
  const {payload} = action;
  try{
    const response: ILoanProcessResponse<IDownloadGSTResponse> = yield LoanProcessServices.downloadGSTData(payload);
    if ((response as any)?.status === 200 && (response as any)?.data.message) {
      const result: IDownloadGSTResponse = {
        message: (response as any)?.data.message,
        id: (response as any)?.data.id,
      };
      yield put(
        loanProcessActions.downloadGSTDataActions.done({
          result: result,
          params: payload
        })
      );
    } else {
      yield put(
        loanProcessActions.downloadGSTDataActions.failed({
          params: payload,
          error: response.errorCode as any,
        })
      );
    }
  } catch(err) {
    yield put(
      loanProcessActions.downloadGSTDataActions.failed(
        (err as any)?.response?.data?.errors || (err as any).message || err
      )
    );
  }
}

function* LoanProcessWatcher() {
  yield takeEvery(
    loanProcessActions.generateGstOtpActions.started,
    generateGstOtp
  );
  yield takeEvery(
    loanProcessActions.validateGstOtpActions.started,
    validateGstOtp
  );
  yield takeEvery(
    loanProcessActions.getTokenActions.started,
    getToken
  );
  yield takeEvery(
    loanProcessActions.downloadGSTDataActions.started,
    downloadGSTData
  );
}

export default LoanProcessWatcher;
