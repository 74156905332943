import React from 'react';
import { Col, Row } from "antd";
import classNames from "classnames";
import { loanProcessSteps } from '../../redux/dependencies';
import './index.scss';
import { ELoanProcessStep } from '../../redux/reducers/typings';
import useLoanProcess from '../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons'
interface IStepProps {
  index: number;
  name: string;
  completed: boolean;
  first: boolean;
  last: boolean;
  current?: boolean
}

interface IStepConnector {
  completed: boolean;
}

const StepConnector = ({ completed }: IStepConnector) => {
  const cssClasses = classNames({
    'connection--wrapper': true,
    'connection--completed': completed
  });
  return (
    <div className={cssClasses}>
      <div className="connector" />
    </div>
  )
}

const Step = ({ index, name, completed, first, last, current }: IStepProps) => {
  let cssClasses = classNames({
    step: true,
    'step--completed': completed,
    'step--start': first,
    'step--end': last,
    'step--current': current
  })
  return (
    <div className={cssClasses}>
      <div className="step__index">
        {!completed && index}
        {completed && <FontAwesomeIcon icon={faCheck} />}
      </div>
      <div className="step__name">{name}</div>
    </div>
  )
}

const ProgressStepper = () => {
  const { loanProcessState: { step } } = useLoanProcess();
  const isLast = (index: number) => index === loanProcessSteps.length;
  const isCompleted = (tempStep: ELoanProcessStep) => step > tempStep || step === ELoanProcessStep.PROCESS_COMPLETE
  const isCurrent = (tempstep: ELoanProcessStep) => step === tempstep
  const steps = loanProcessSteps.map((loanStep, idx) =>
    <React.Fragment key={loanStep.index}>
      <Col>
        <Step
          {...loanStep}
          completed={isCompleted(loanStep.type[0])}
          first={loanStep.index === 1}
          last={isLast(loanStep.index)}
          current={isCurrent(loanStep.type[0])}
        />
      </Col>
      {
        !isLast(loanStep.index) && (
          <Col className="connector--column">
            <StepConnector completed={isCompleted(loanStep.type[0])} />
          </Col>
        )
      }
    </React.Fragment>
  );

  return (
    <div className='progress-stepper'>
      <Row className='progress-stepper__steps-wrapper' justify='space-between' align='middle'>
        {steps}
      </Row>
    </div>
  )
};

export default ProgressStepper;