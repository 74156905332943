import { Col, Row } from "antd";
import React, { useEffect } from "react";
import ProgressContainer from "../../components/ProgressContainer";
import useLoanProcess from "../../hooks";
import { IDownloadGSTRequest } from "../../redux/reducers/typings";
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {LoadingOutlined} from '@ant-design/icons';

const Success = () => {
  const {loanProcessState: {downloadId, loading, gstIn, gstUsername, error}, downloadGSTData} = useLoanProcess();
  useEffect(() => {
    if(!downloadId){
      downloadGSTData({gstIn, gstUsername} as IDownloadGSTRequest)
    }
  }, [downloadId, gstIn, gstUsername, downloadGSTData])
  return (
    <div id='success-page'>
      <ProgressContainer>
        <>
          {loading && (
            <Row justify='center' align='middle'>
              <Col>
              <LoadingOutlined style={{color:'#5fbd71', animationDuration: '0.75s', fontSize: '36px'}} rotate={20}/>
              </Col>
            </Row>
          )}
          { (!loading && error) && 
            <Row>
              { error && 
                <Col span={24}>
                  <div className='text--error text--capitalize text--center'>
                    {error}
                  </div>
                </Col>
              }
              <Col span={24}>
                <div>
                  There was some issue downloading your GST data. Please re-generate and validate the OTP
                </div>
              </Col>
            </Row>
            }
          { (!loading && downloadId) &&
            <>
              <Row>
                <Col>
                  <h1> Your OTP has been successfully validated</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="text">
                    We are getting the data from GSTN. We will be in touch with you shortly. Meanwhile, if you need anything please WhatsApp us on
                  </span>
                  <span className="text--highlight"> +91 9663458899</span>
                </Col>
              </Row>
            </>
          }
        </>
      </ProgressContainer>
    </div>
  );
};

export default Success;