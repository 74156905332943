import React from 'react';
import { useHistory } from 'react-router';
import useLoanProcess from '../../hooks';
import { loanProcessSteps } from '../../redux/dependencies'

const StateRouteManager = () => {
  const { loanProcessState: { step } } = useLoanProcess();
  const history = useHistory();
  const route = loanProcessSteps.find(loanStep => loanStep.type.includes(step))?.route || '/';
  setTimeout(() => {
    if (history.location.pathname !== route) {
      history.push(route)
    }
  }, 0)
  return (<></>)
}

export default StateRouteManager;