import axios, { AxiosResponse } from "axios"
import { ITokenRequest, ITokenResponse } from "../../redux/reducers/typings"
import { IDownloadGSTRequest, IDownloadGSTResponse, IGenerateOtpRequest, IGenerateOtpResponse, ILoanProcessResponse, IValidateOtpRequest, IValidateOtpResponse } from "../../redux/reducers/typings"

const apiUrl = 'https://qa-backend.jitfin.co/api/services';
const apiVer = 'v1';
export interface ILoanProcessServices{
  generateOtp: (payload: IGenerateOtpRequest) => Promise<ILoanProcessResponse<IGenerateOtpResponse>>;
  validateOtp: (payload: IValidateOtpRequest) => Promise<ILoanProcessResponse<IValidateOtpResponse>>;
  getToken: (payload: ITokenRequest) => void;
  downloadGSTData: (payload: IDownloadGSTRequest) => Promise<ILoanProcessResponse<IDownloadGSTResponse>>;
}
const loanProcessServices = () => {

  let token ='aml0ZmluX3BpcGVzX3FhX3VzZXJuYW1lOmppdGZpbl9waXBlc19xYV9wYXNz';

  const generateOtp = (payload: IGenerateOtpRequest): Promise<ILoanProcessResponse<IGenerateOtpResponse>> => {
    const {gstIn: gstin, gstUsername: gst_username, partner} = payload;
    const url = `${apiUrl}/${apiVer}/generate-wep-otp`;
    // return new Promise((resolve,reject) => (resolve({
    //   success: true,
    //   result: {
    //     otpGenerated: true,
    //     message: 'Otp generated',
    //     error: ''
    //   } as IGenerateOtpResponse,
    //   errorCode: ''
    // })));
    return axios.post(url, {gstin, gst_username, partner}, { headers: {
      'Authorization': `Basic ${token}` 
    }}); 
  }

  const validateOtp = (payload: IValidateOtpRequest): Promise<ILoanProcessResponse<IValidateOtpResponse>> => {
    const url = `${apiUrl}/${apiVer}/validate-wep-otp`;
    // return new Promise((resolve, reject) => (resolve({
    //   success: true,
    //   result: {
    //     otp: 2931,
    //     validated: true,
    //     message: 'OTP validated'
    //   } as IValidateOtpResponse,
    //   errorCode: ''
    // } as any)));
    const {gstIn: gstin, otp, partner} = payload;
    const sanitisedPayload = {gstin, otp, partner};
    return axios.post(url, sanitisedPayload, { headers: {
      'Authorization': `Basic ${token}` 
    }});
  }

  const downloadGSTData = (payload: IDownloadGSTRequest): Promise<ILoanProcessResponse<IDownloadGSTResponse>> => {
    const url = `${apiUrl}/${apiVer}/download-wep-gstr`;
    // return new Promise((resolve, reject) => (resolve({
    //   success: true,
    //   result: {
    //     otp: 2931,
    //     validated: true
    //   } as IValidateOtpResponse,
    //   errorCode: ''
    // })));
    // const gstin = '29AAECJ4499F1Z4'; const gst_username = 'Jit_2019';
    const {gstIn: gstin, gstUsername: gst_username} = payload;
    const sanitisedPayload = {gstin, gst_username};
    return axios.post(url, sanitisedPayload, { headers: {
      'Authorization': `Basic ${token}` 
    }});
  }

  const getToken = async (params: ITokenRequest) => {
    const url = `${apiUrl}/${apiVer}/auth-token`;
    await axios.get(url, {params}).then( (data: AxiosResponse<ITokenResponse>) => {
      token = data?.data?.token;
    });
  }

  return {generateOtp, validateOtp, getToken, downloadGSTData} as ILoanProcessServices;
}
const LoanProcessServices = loanProcessServices();
export default LoanProcessServices;