import actionCreatorFactory from "typescript-fsa";
import { IGenerateOtpResponse, IGSTCredentials, ITokenRequest, ITokenResponse, IValidateOtpRequest, IValidateOtpResponse } from "../reducers/typings";
import { IDownloadGSTRequest, IDownloadGSTResponse } from "../reducers/typings";

const actionCreator = actionCreatorFactory();

const getTokenActions = actionCreator.async<ITokenRequest, ITokenResponse, Error>("GET_ACCESS_TOKEN");

const beginLoanProcessActions = actionCreator.async<void, void, void>("BEGIN_LOAN_PROCESS");

const generateGstOtpActions = actionCreator.async<IGSTCredentials, IGenerateOtpResponse, Error>('GENERATE_GST_ACTIONS');

const validateGstOtpActions = actionCreator.async<IValidateOtpRequest, IValidateOtpResponse, Error>('VALIDATE_GST_OTP');

const downloadGSTDataActions = actionCreator.async<IDownloadGSTRequest, IDownloadGSTResponse, Error>('DOWNLOAD_GST_DATA');

// const timerActions = actionCreator.async<IValidateOtpRequest, IValidateOtpResponse, Error>('TIMER_ACTIONS');

const loanProcessActions = {
  beginLoanProcessActions,
  generateGstOtpActions,
  validateGstOtpActions,
  getTokenActions,
  downloadGSTDataActions
};

export default loanProcessActions;